import React, { useEffect, useState } from "react";
import Wrapper from "./Wrapper";
import "../../assets/css/editprofile.css";
import fleg from "../../assets/images/argentina.jpg";
import axios from "axios";
import * as API from "../../api";
import { toast } from "react-toastify";
const Editprofile = () => {
  const [profile, setProfile] = useState({
    account_type: "",
    address_po: "",
    city: "",
    country: "",
    dyno_status: false,
    email: "",
    evc_status: false,
    name: "",
    phone_number: "",
    skype_address: "",
    title: "",
    zipcode: "",
    tools: [],
    system_language: "",
    company_name: "",
  });
  const [errors, setErrors] = useState({});
  const [profileImage, setProfileImage] = useState(null)
  const [imagePreview, setImagePreview] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);

  const token = localStorage.getItem("Access-Token");
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };
  const fetchProfile = async () => {
    try {
      if (!token) {
        window.location.href = "/login";
        return;
      }
      const response = await API.getProfile(token);
      if (response.status_code === 200) {
        setProfile(response.detail);

        // If profile image exists, prepend the data URI prefix
        if (response.detail.profile_img) {
          setProfileImage(
            `data:image/png;base64,${response.detail.profile_img}`
          );
        }
      }
    } catch (error) {
      console.error("Failed to fetch profile:", error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [token]);
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      if (!token) {
        window.location.href = "/login";
        return;
      }

      const payload = {
        ...profile,
        profile_img: imageBase64 || "null",
        image_name: profileImage ? profileImage.name : null,
      };

      const response = await API.updateUserProfile(payload, token);

      if (response.status_code === 200) {
        toast.success("Profile updated successfully");
        fetchProfile();
      } else {
        toast.error(response.message || "Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };
  const validateForm = () => {
    let formErrors = {};
    if (!profile.account_type) {
      formErrors.account_type = "Account type is required";
    }

    if (!profile.name || profile.name.trim() === "") {
      formErrors.name = "Name is required";
    }
    if (!profile.title) {
      formErrors.title = "Title is required";
    }
    if (!profile.address_po || profile.address_po.trim() === "") {
      formErrors.address_po = "Address is required";
    }
    if (!profile.zipcode || !/^\d{5,6}$/.test(profile.zipcode)) {
      formErrors.zipcode = "Valid zipcode is required";
    }
    if (!profile.city || profile.city.trim() === "") {
      formErrors.city = "City is required";
    }
    if (!profile.country) {
      formErrors.country = "Country is required";
    }
    if (
      !profile.phone_number ||
      !/^\+?[\d\s-]{10,15}$/.test(profile.phone_number)
    ) {
      formErrors.phone_number = "Valid phone number is required";
    }
    if (!profile.email || !/\S+@\S+\.\S+/.test(profile.email)) {
      formErrors.email = "Valid email is required";
    }
    if (
      profile.account_type === "business" &&
      (!profile.company_name || profile.company_name.trim() === "")
    ) {
      formErrors.company_name =
        "Company name is required for business accounts";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  const validateImage = (file) => {
    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/webp",
    ];
    const maxSize = 20 * 1024 * 1024; // 20MB

    if (!file) {
      toast.error("Please select an image");
      return false;
    }

    if (!allowedTypes.includes(file.type)) {
      toast.error(
        "Invalid file type. Only JPEG, JPG, PNG, GIF, and WEBP are allowed."
      );
      return false;
    }

    if (file.size > maxSize) {
      toast.error("File size exceeds 20MB limit");
      return false;
    }
    return true;
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (validateImage(file)) {
      setProfileImage(file);

      // Create a preview URL for display
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);

        // Also store the base64 string without the prefix for API submission
        const base64String = reader.result.split(",")[1];
        setImageBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  const toolOptions = [
    "Alientech KESSv2",
    "FG Technology EOBD2",
    "Alientech K-TAG",
    "Frieling Racing IFLASH",
    "Alientech KESS3",
    "Frieling Racing IBOOT",
    "Autotuner",
    "Frieling Racing SPI Wizard",
    "bFlash",
    "HP Tuners MPVI2",
    "Bitbox",
    "AMT Cartech Limited MPPS",
    "CMDFlash",
    "Piasini Engineering Serial Suite",
    "ByteShooter Toolbox",
    "FC-200",
    "New Genius",
    "Truck Explorer",
    "New Trasdata",
    "ABPROG",
  ];
  const handleCheckboxChange = (toolName) => {
    setProfile((prevProfile) => {
      const updatedTools = prevProfile.tools.includes(toolName)
        ? prevProfile.tools.filter((tool) => tool !== toolName)
        : [...prevProfile.tools, toolName];

      return { ...prevProfile, tools: updatedTools };
    });
  };

  return (
    <>
      <Wrapper>
        <div className="container-fluild">
          <div className="row">
            <div className="col-12 ">
              <div className="profile_link">
                <div className="profile_heading">Edit your profile</div>
                <div className="d-flex gap-2">
                  <a href="/dashboard" className="dashboard_profile">
                    Dashboard
                    <span>
                      <svg
                        className="dashboard_profile_link"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                      </svg>
                    </span>
                  </a>
                  <a href="/editprofile" className="dashboard_profile">
                    Edit your profile
                  </a>
                </div>
              </div>
              <form action="" className="profile_form shadow-lg my-3 shadow-lg">
                <h4 className="Personal_information">Personal information</h4>
                <div className="">
                  <h1 className="Account_type mt-3">Account type</h1>
                  <span className="radio_btn_profile">
                    <label htmlFor="personal" className="ml-2">
                      <input
                        type="radio"
                        className="Persnal_radio"
                        name="account_type"
                        id="personal"
                        onChange={handleInputChange}
                        checked={profile.account_type === "personal"}
                        value="personal"
                      />
                      Personal
                    </label>
                    <label htmlFor="business" className="ml-2">
                      <input
                        type="radio"
                        className="Persnal_radio"
                        name="account_type"
                        id="business"
                        onChange={handleInputChange}
                        checked={profile.account_type === "business"}
                        value="business"
                      />
                      Business
                    </label>
                  </span>
                </div>

                {profile.account_type === "business" && (
                  <div className="my-3">
                    <h1 className="Company_name mt-2">Company Name</h1>
                    <input
                      type="text"
                      className="Company_tab"
                      placeholder="Company Name"
                      name="company_name"
                      onChange={handleInputChange}
                      value={profile.company_name || ""}
                    />
                       {errors.company_name && (
                      <div className="text-danger">{errors.company_name}</div>
                    )}
                  </div>
                )}

                <div className="d-flex title_name_tab my-3">
                  <div className="title_name_tab_one">
                    <h1 className="Company_name mt-2">Title </h1>
                    <select
                      className={`title_tab ${
                        errors.title ? "is-invalid" : ""
                      }`}
                      name="title"
                      value={profile.title}
                      onChange={handleInputChange}
                    >
                      <option value="">Mr</option>
                      <option value="">Mrs</option>
                    </select>
                    {errors.title && (
                      <div className="text-danger">{errors.title}</div>
                    )}
                  </div>
                  <div className="title_name_tab_two">
                    <h1 className="Company_name mt-2">Name </h1>
                    <input
                      type="text"
                      className={`Name_tab ${errors.name ? "is-invalid" : ""}`}
                      placeholder="Name"
                      name="name"
                      onChange={handleInputChange}
                      value={profile.name}
                    />
                    {errors.name && (
                      <div className="text-danger">{errors.name}</div>
                    )}
                  </div>
                </div>
                <div className="my-3">
                  <h1 className="Company_name mt-2">Address/PO box</h1>
                  <input
                    type="text"
                    className={`Company_tab ${
                      errors.address_po ? "is-invalid" : ""
                    }`}
                    placeholder="Address"
                    name="address_po"
                    onChange={handleInputChange}
                    value={profile.address_po}
                  />
                  {errors.address_po && (
                    <div className="text-danger">{errors.address_po}</div>
                  )}
                </div>
                <div className="d-flex title_name_tab my-3">
                  <div className="title_name_tab_one">
                    <h1 className="Company_name mt-2">Zipcode </h1>
                    <input
                      type="text"
                      className={`title_tab ${
                        errors.zipcode ? "is-invalid" : ""
                      }`}
                      placeholder="Pin code"
                      name="zipcode"
                      onChange={handleInputChange}
                      value={profile.zipcode}
                    />
                    {errors.zipcode && (
                      <div className="text-danger">{errors.zipcode}</div>
                    )}
                  </div>
                  <div className="title_name_tab_two">
                    <h1 className="Company_name mt-2">City Name </h1>
                    <input
                      type="text"
                      className={`Name_tab ${errors.city ? "is-invalid" : ""}`}
                      placeholder="City Name"
                      name="city"
                      onChange={handleInputChange}
                      value={profile.city}
                    />
                  </div>
                  {errors.city && (
                    <div className="text-danger">{errors.city}</div>
                  )}
                </div>
                <div className="my-3">
                  <h1 className="Company_name mt-2">Country </h1>
                  <select
                    className={`Country_tab ${
                      errors.country ? "is-invalid" : ""
                    }`}
                    name="country"
                    value={profile.country}
                    onChange={handleInputChange}
                  >
                    <option value="india">India</option>
                    <option value="usa">USA</option>
                  </select>
                  {errors.country && (
                    <div className="text-danger">{errors.country}</div>
                  )}
                </div>
                <div className="icon_choice">
                  <div className="title_name_tab_one">
                    <h1 className="Company_name mt-2">
                      Skype address(optional)
                    </h1>
                    <input
                      type="text"
                      className="title_tab"
                      placeholder="Skype address"
                      name="skype_address"
                      onChange={handleInputChange}
                      value={profile.skype_address || ""}
                    />
                  </div>
                  <div className="city_tab_profile pt-2">
                    <div>Phone number</div>
                    <input
                      type="text"
                      className={`email_tab_choice_profile my-2 ${
                        errors.phone_number ? "is-invalid" : ""
                      }`}
                      name="phone_number"
                      onChange={handleInputChange}
                      placeholder="Phone number"
                      value={profile.phone_number}
                    />
                    <span className="fleg_tab">
                      {" "}
                      <img src={fleg} alt="" width={30} height={20} />
                      <select name="" id="" className="fleg_dropdown"></select>
                    </span>
                  </div>
                  {errors.phone_number && (
                    <div className="text-danger">{errors.phone_number}</div>
                  )}
                </div>
                <div className="my-3">
                  <h1 className="Company_name mt-2">Email address</h1>
                  <input
                    type="text"
                    className="Company_tab"
                    placeholder="Email address"
                    name="email"
                    onChange={handleInputChange}
                    readOnly
                    value={profile.email}
                  />
                </div>
                <div className="my-3">
                  <h1 className="Company_name mt-2">System language</h1>
                  <input
                    type="text"
                    className="Company_tab"
                    placeholder="System language"
                    name="system_language"
                    onChange={handleInputChange}
                    value={profile.system_language}
                  />
                </div>
                <div className="Profile_photo">
                  <p>
                    Profile photo (optional) 300px by 300px <br></br>Only .jpeg,
                    .jpg, .png, .gif and .webp files smaller than 20MB
                  </p>
                  <div className="Profile_photo_tab">
                    <div className="photo_add">
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="Profile Preview"
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                      ) : profileImage ? (
                        <img
                          src={profileImage}
                          alt="Profile"
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                      ) : (
                        <p className="photo_uploaded">No image uploaded yet</p>
                      )}
                    </div>
                    <div className="photo_add_second">
                      <input
                        type="file"
                        id="profileImageUpload"
                        style={{ display: "none" }}
                        accept=".jpg,.jpeg,.png,.gif,.webp"
                        onChange={handleImageUpload}
                      />
                      <button
                        type="button"
                        className="Browse_files"
                        onClick={(e) => {
                          e.preventDefault();
                          document.getElementById("profileImageUpload").click();
                        }}
                      >
                        Browse files on your device
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluild">
          <div className="row">
            <div className="col-12 ">
              <form
                action=""
                className="profile_form_extra shadow-lg my-3 shadow-lg"
              >
                <h4 className="Personal_information">Extra information</h4>
                <div className="my-3">
                  <h1 className="Company_name mt-2">
                    Are you registered at EVC.de?{" "}
                  </h1>
                  <select
                    className="title_tab_extra"
                    name="evc_status"
                    value={profile.evc_status ? "Yes" : "No"}
                    onChange={(e) =>
                      setProfile({
                        ...profile,
                        evc_status: e.target.value === "Yes",
                      })
                    }
                  >
                    <option value="">Make your choice</option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>
                <div className="my-3">
                  <h1 className="Company_name mt-2">Do you have a Pro? </h1>
                  <select
                    className="title_tab_extra"
                    name="dyno_status"
                    value={profile.dyno_status ? "Yes" : "No"}
                    onChange={(e) =>
                      setProfile({
                        ...profile,
                        dyno_status: e.target.value === "Yes",
                      })
                    }
                  >
                    <option value="">Make your choice</option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>
                <div className="">
                  <h1 className="Company_name mt-2">Tools (optional)</h1>
                  {toolOptions
                    .reduce((acc, tool, index) => {
                      if (index % 2 === 0) {
                        acc.push([tool, toolOptions[index + 1]]);
                      }
                      return acc;
                    }, [])
                    .map((pair, idx) => (
                      <div key={idx} className="checkbox_profile">
                        {pair.map(
                          (tool, i) =>
                            tool && (
                              <div
                                key={i}
                                className="d-flex gap-2 checkbox_profile_main"
                              >
                                <input
                                  type="checkbox"
                                  className="checkbox_tab_profile"
                                  checked={profile.tools.includes(tool)}
                                  onChange={() => handleCheckboxChange(tool)}
                                />
                                <p className="pt-3">{tool}</p>
                              </div>
                            )
                        )}
                      </div>
                    ))}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluild">
          <div className="row">
            <div className="col-12 ">
              <form
                action=""
                className="profile_form_extra shadow-lg my-3 shadow-lg"
              >
                <h4 className="Personal_information">Change password</h4>
                <div className="my-3">
                  <h1 className="Company_name mt-2">
                    Current password(optional)
                  </h1>
                  <input
                    type="text"
                    className="Company_tab"
                    placeholder="Current password"
                  />
                </div>
                <div className="my-3">
                  <h1 className="Company_name mt-2">Password(optional)</h1>
                  <input
                    type="text"
                    className="Company_tab"
                    placeholder="Pssword"
                  />
                </div>
                <div className="my-3">
                  <h1 className="Company_name mt-2">
                    Password confirmation(optional)
                  </h1>
                  <input
                    type="text"
                    className="Company_tab"
                    placeholder="Password confirmation"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluild">
          <div className="row">
            <div className="col-12 ">
              <form
                action=""
                className="profile_form_extra shadow-lg my-3 shadow-lg"
              >
                <div className="my-3 Cancel_button">
                  <button className="Cancel_btn">Cancel</button>
                  <button className="save_btn" onClick={handleUpdateProfile}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluild">
          <div className="row">
            <div className="col-12">
              <form
                action=""
                className="profile_form_extra shadow-lg my-3 shadow-lg"
              >
                <h1 className="delete_account">Danger zone</h1>
                <p className="delete_button_pra">
                  You can delete your account using the button below. This
                  action cannot be reversed.
                </p>
                <div className="my-3">
                  <button className="delete_button">
                    Yes,delete my account
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Editprofile;
