import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/navbar.css";
import { Link } from "react-router-dom";
import { getProfile } from "../../api";
import { useSelector } from "react-redux"; 
const Navbar = () => {
  const [selectedColor, setSelectedColor] = useState(
    localStorage.getItem("theme-color") || "#19A4E0"
  );
  
  const [showCreditsDropdown, setShowCreditsDropdown] = useState(false);
  const [credits, setCredits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const dropdownRef = useRef(null);
  
  const { items } = useSelector(state => state.cart);
  const cartItemsCount = items.reduce((acc, item) => acc + item.quantity, 0);

  const menu = [
    {
      href: "#",
      icon: "bi bi-database",
      onClick: () => {
        getCredits(); 
        setShowCreditsDropdown(!showCreditsDropdown);
      }
    },
    {
      href: "/order-history",
      icon: "bi bi-clock-history",
    },
    {
      href: "/credit-transactions",
      icon: "bi bi-credit-card",
    },
    {
      href: "/cart",
      icon: "bi bi-cart3",
      badge: cartItemsCount > 0 ? cartItemsCount : null
    },
  ];
  const getCredits = async () => {
    try {
      setIsLoading(true);
      const response = await getProfile();
      if (response && response.status_code === 200 && response.detail) {
        const userCredits = response.detail.credit || [];
        const masterCredits = userCredits.filter(credit => credit.type && credit.type.includes('master-credits'));
        setCredits(masterCredits);
      }
    } catch (error) {
      console.error("Error fetching credits:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const setColorScheme = (e) => {
    setSelectedColor(e.target.value);
    localStorage.setItem("themeColor", e.target.value);
    document.documentElement.style.setProperty("--theme-color", e.target.value);
  };
            
  useEffect(() => {
    getCredits();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCreditsDropdown(false);
      }
    };
    if (showCreditsDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCreditsDropdown]);

  const formatCreditType = (type) => {
    if (!type || typeof type !== 'string') {
      return ''; 
    }
    return type.split('-')[0].charAt(0).toUpperCase() + type.split('-')[0].slice(1);
  };
  

  return (
    <>
      <div className="container-fluid navbar_Dashboard_main">
        <div className="row navbar_Dashboard">
          <div className="col-6">
            <span className="Dashboard_icon_search_1">
              <i className="bi bi-search Dashboard_icon_search text-primary"></i>
            </span>
            <input
              className="search_navbar"
              type="text"
              placeholder="Enter your search term"
            />
          </div>
          <div className="col-4 Dashboard_search">
            <div className="navber_icon_tab gap-2 mx-3">
              <div className="Dashboard_text_profile py-1">
                {/* <span className="Dashboard_setting-color btn btn-lg btn-hover-shadow btn-light-light btn-only-icon"> */}
                  {/* <select
                    onChange={setColorScheme}
                    value={selectedColor}
                    className="rounded-circle"
                    style={{
                      backgroundColor: selectedColor,
                    }}
                  >
                    <option
                      value="#19A4E0"
                      selected
                      style={{ backgroundColor: "#19A4E0" }}
                    ></option>
                    <option
                      value="#1B5CB1"
                      style={{ backgroundColor: "#1B5CB1" }}
                    ></option>
                    <option
                      value="#198DE0"
                      style={{ backgroundColor: "#198DE0" }}
                    ></option>
                  </select> */}
                {/* </span> */}
              </div>
              {menu?.map((item, index) => (
                <div className="Dashboard_text_profile py-1" key={index}>
                  <span className="Dashboard_profile_tab position-relative">
                    {item.onClick ? (
                      <a 
                        href={item.href} 
                        className="text-light fs-5" 
                        onClick={(e) => {
                          e.preventDefault();
                          item.onClick();
                        }}
                      >
                        <i className={item.icon}></i>
                        {item.badge && (
                          <span 
                            className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                            style={{ fontSize: "0.6rem" }}
                          >
                            {item.badge}
                            <span className="visually-hidden">items in cart</span>
                          </span>
                        )}
                      </a>
                    ) : (
                      <Link className="text-light fs-5" to={item.href}>
                        <i className={item.icon}></i>
                        {item.badge && (
                          <span 
                            className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                            style={{ fontSize: "0.6rem" }}
                          >
                            {item.badge}
                            <span className="visually-hidden">items in cart</span>
                          </span>
                        )}
                      </Link>
                    )}
                    
                    {/* Credits dropdown - now filtered to show only master-credits */}
                    {item.icon === "bi bi-database" && showCreditsDropdown && (
                      <div 
                        ref={dropdownRef}
                        className="position-absolute bg-white shadow rounded py-2 px-3" 
                        style={{
                          top: "40px", 
                          right: "0", 
                          zIndex: 1000, 
                          minWidth: "200px",
                          textAlign: "left"
                        }}
                      >
                        <h6 className="fw-bold mb-2">My Credits</h6>
                        {isLoading ? (
                          <div className="text-center my-2">
                            <div className="spinner-border spinner-border-sm" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <>
                            {credits.length > 0? (
                              credits.map((credit, index) => (
                                <div className="mb-1" key={index}>
                                  {formatCreditType(credit.type)}: {credit.value}
                                </div>
                              ))
                            ) : (
                              <div className="mb-2">No master credits available</div>
                            )}
                          </>
                        )}
                       
                        <Link 
                          to="/buy-credits" 
                          className="btn btn-primary btn-sm w-100"
                        >
                          Buy Credits
                        </Link>
                      </div>
                    )}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;