import React, { useState, useEffect } from "react";
import Wrapper from "../Wrapper";
import TopCrumb from "../../header/TopCrumb";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, replaceCreditItem } from "../../../cartSlice"; 
import { toast } from "react-toastify";

const BuyCredit = () => {
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedTab, setSelectedTab] = useState("master-credits");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.items);

    // Products data
    const products = {
        "master-credits": [
            { credits: 0.5, price: "€50.00", discount: null },
            { credits: 1, price: "€90.00", discount: null },
            { credits: 2, price: "€180.00", discount: null },
            { credits: 5, price: "€400.00", discount: "Save €50.00" },
            { credits: 10, price: "€700.00", discount: "Save €200.00", popular: true },
            { credits: 25, price: "€1,625.00", discount: "Save €625.00" },
            { credits: 50, price: "€3,000.00", discount: "Save €1,500.00" },
            { credits: 100, price: "€6,000.00", discount: "Save €4,000.00" },
        ],
        "evc-credits": [
            { credits: "500", price: "$5", discount: "5% Off", popular: false },
            { credits: "1500", price: "$15", discount: "15% Off", popular: false },
            { credits: "3000", price: "$30", discount: "", popular: false },
        ],
        "tuning-tools": [
            { credits: "1", price: "$50", discount: "20% Off", popular: false },
            //   { credits: "2", price: "$100", discount: "", popular: false },
        ],
    };

    // Check if there are any credit items in the cart and set the selected card accordingly
    useEffect(() => {
        // Find any credit items in the cart
        const creditItem = cartItems.find(item => 
            item.name.includes("Master") || item.name.includes("EVC") || item.name.includes("WinOLS5")
        );
        
        if (creditItem) {
            // Extract type from the item
            const type = creditItem.details.type;
            setSelectedTab(type);
            
            // Find the index of the product in the array by matching credits
            const index = products[type].findIndex(
                product => product.credits.toString() === creditItem.details.totalCredits.toString()
            );
            
            if (index !== -1) {
                setSelectedCard(index);
            }
        }
    }, [cartItems]);

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        setSelectedCard(null); 
    };

    const handleCardClick = (index) => {
        setSelectedCard(index);
    };

    const handleButtonClick = () => {
        if (selectedCard === null) {
            toast.error("Please select a product first");
            return;
        }

        const selectedProduct = products[selectedTab][selectedCard];
        const creditType = selectedTab; 
        
        // Create the new item
        const itemToAdd = {
            id: `${selectedTab}-${selectedCard}-${Date.now()}`,
            name: selectedTab === "tuning-tools" ? "WinOLS5 (New user)" : 
                 `${selectedProduct.credits} ${selectedTab === "master-credits" ? "Master" : "EVC"} Credits`,
            quantity: 1,
            details: {
                totalCredits: selectedProduct.credits,
                type: creditType 
            },
            price: selectedProduct.price
        };
        
        // Use replaceCreditItem instead of addToCart to replace any existing credit items
        dispatch(replaceCreditItem(itemToAdd));
        toast.success("Added to cart successfully!");
        
        // Store the selected card information in localStorage
        localStorage.setItem('selectedCreditTab', selectedTab);
        localStorage.setItem('selectedCreditCard', selectedCard);
        
        navigate("/buy-cart");
    };
    
    return (
        <>
            <Wrapper>
                <TopCrumb route={"Buy Credit"} />
                <div className="container buy_container mt-4">
                    <div className="row">

                        <div className="col-md-9 fsection p-0">
                            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 top-borde">
                                <div className="d-flex gap-3 ms-3 ">
                                    <i className="bi bi-database-fill-add text-primary icon-bordered" style={{ fontSize: "30px" }}></i>
                                    <i className="bi bi-credit-card-2-front text-primary icon2" style={{ fontSize: "30px" }}></i>
                                </div>
                                <button onClick={handleButtonClick} className="btn btn-primary me-3">Go to next step</button>
                            </div>

                            <p className="ms-3 p-2" style={{ fontSize: "20px" }}>Products</p>
                            <ul className="nav nav-tabs ms-3" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a
                                        className={`nav-link ${selectedTab === "master-credits" ? "active" : ""}`}
                                        id="master-credits-tab"
                                        data-bs-toggle="tab"
                                        href="#master-credits"
                                        role="tab"
                                        onClick={() => handleTabChange("master-credits")}
                                    >
                                        Master Credits
                                    </a>
                                </li>
                                {/* <li className="nav-item" role="presentation">
                                    <a
                                        className={`nav-link ${selectedTab === "evc-credits" ? "active" : ""}`}
                                        id="evc-credits-tab"
                                        data-bs-toggle="tab"
                                        href="#evc-credits"
                                        role="tab"
                                        onClick={() => handleTabChange("evc-credits")}
                                    >
                                        EVC Credits
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a
                                        className={`nav-link ${selectedTab === "tuning-tools" ? "active" : ""}`}
                                        id="tuning-tools-tab"
                                        data-bs-toggle="tab"
                                        href="#tuning-tools"
                                        role="tab"
                                        onClick={() => handleTabChange("tuning-tools")}
                                    >
                                        Tuning Tools
                                    </a>
                                </li> */}
                            </ul>


                            <div className="tab-content mt-3" id="myTabContent">
                                {/* Master Credits Tab */}
                                <div className={`tab-pane fade ${selectedTab === "master-credits" ? "show active" : ""}`} id="master-credits" role="tabpanel">
                                    <div className="row mt-3 p-4">
                                        {products["master-credits"]?.map((product, index) => (
                                            <div className="col-6 col-md-4 col-lg-3 mb-4" key={index}>
                                                <div 
                                                    className={`product-card text-center p-3`} 
                                                    onClick={() => handleCardClick(index)} 
                                                    style={{ 
                                                        borderRadius: "8px", 
                                                        border: selectedCard === index && selectedTab === "master-credits" ? "2px solid #007bff" : "3px dashed #c6e9f5", 
                                                        boxShadow: selectedCard === index && selectedTab === "master-credits" ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "0px 2px 4px rgba(0, 0, 0, 0.1)", 
                                                        cursor: "pointer", 
                                                        position: "relative" 
                                                    }}
                                                >
                                                    {product.popular && <span className="badge bg-success text-white mb-2" style={{ position: "absolute", top: "0px", left: "102px", padding: '8px 11px', visibility: product.popular ? "visible" : "hidden" }}>Popular</span>}
                                                    <div className="bg-dark text-white p-3 rounded" style={{ fontSize: "14px" }}>
                                                        <p className="m-0"><strong style={{ fontSize: "20px" }}>{product.credits}</strong> </p>
                                                        <p style={{ fontSize: "14px" }}>Master credits</p>
                                                    </div>
                                                    <h5 className="mt-3">{product.price}</h5>
                                                    {/* {product.discount && <p className="text-success">{product.discount}</p>} */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* EVC Credits Tab */}

                                <div className={`tab-pane fade ${selectedTab === "evc-credits" ? "show active" : ""}`} id="evc-credits" role="tabpanel">
                                    <div className="row mt-3">
                                        {products["evc-credits"]?.map((product, index) => (
                                            <div className="col-6 col-md-4 col-lg-3 mb-4" key={index}>
                                                <div 
                                                    className={`product-card text-center p-3`} 
                                                    onClick={() => handleCardClick(index)} 
                                                    style={{ 
                                                        borderRadius: "8px", 
                                                        border: selectedCard === index && selectedTab === "evc-credits" ? "2px solid #007bff" : "3px dashed #c6e9f5", 
                                                        boxShadow: selectedCard === index && selectedTab === "evc-credits" ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "0px 2px 4px rgba(0, 0, 0, 0.1)", 
                                                        cursor: "pointer", 
                                                        position: "relative" 
                                                    }}
                                                >
                                                    {product.popular && <span className="badge bg-success text-white mb-2" style={{ position: "absolute", top: "10px", left: "10px", visibility: product.popular ? "visible" : "hidden" }}>Popular</span>}
                                                    <div className="bg-dark text-white p-3 rounded" style={{ fontSize: "14px" }}>
                                                        <p className="m-0"><strong>{product.credits}</strong> EVC credits</p>
                                                    </div>
                                                    <h5 className="mt-3">{product.price}</h5>
                                                    {/* {product.discount && <p className="text-success">{product.discount}</p>} */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Tuning Tools Tab */}
                                <div className={`tab-pane fade ${selectedTab === "tuning-tools" ? "show active" : ""}`} id="tuning-tools" role="tabpanel">
                                    <div className="row mt-3">
                                        {products["tuning-tools"]?.map((product, index) => (
                                            <div className="col-6 col-md-4 col-lg-3 mb-4" key={index}>
                                                <div 
                                                    className={`product-card text-center p-3`} 
                                                    onClick={() => handleCardClick(index)} 
                                                    style={{ 
                                                        borderRadius: "8px", 
                                                        border: selectedCard === index && selectedTab === "tuning-tools" ? "2px solid #007bff" : "3px dashed #c6e9f5", 
                                                        boxShadow: selectedCard === index && selectedTab === "tuning-tools" ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "0px 2px 4px rgba(0, 0, 0, 0.1)", 
                                                        cursor: "pointer", 
                                                        position: "relative" 
                                                    }}
                                                >
                                                    {product.popular && <span className="badge bg-success text-white mb-2" style={{ position: "absolute", top: "10px", left: "10px", visibility: product.popular ? "visible" : "hidden" }}>Popular</span>}
                                                    <div className="bg-dark text-white p-3 rounded" style={{ fontSize: "14px" }}>
                                                        <p className="m-0">
                                                            WinOLS5 (New user)</p>
                                                    </div>
                                                    <h5 className="mt-3">{product.price}</h5>
                                                    {/* {product.discount && <p className="text-success">{product.discount}</p>} */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-3 bottom-borde">
                                <div className="d-flex gap-3 ms-3">
                                    <i className="bi bi-database-fill-add text-primary icon-bordered" style={{ fontSize: "30px" }}></i>
                                    <i className="bi bi-credit-card-2-front text-primary icon2" style={{ fontSize: "30px" }}></i>
                                </div>
                                <button onClick={handleButtonClick} className="btn btn-primary me-3">Go to next step</button>
                            </div>
                        </div>


                        <div className="col-md-3">
                            {/* This empty column could be used for a cart preview if desired */}
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    );
};

export default BuyCredit;