import React from "react";
import { Card } from "react-bootstrap";
import VehicleSummary from "./CardDisplay";

const RightSideCard = ({ active, vehicleInfoData, additionalTuning }) => {
  const tuningTypes = [
    { id: "1", title: "Car Tuning (Stage 1)", credits: 1.0 },
    { id: "2", title: "Car Tuning (Stage 2)", credits: 1.5 },
    { id: "3", title: "Car Tuning (Stage 3)", credits: 3.0},
    { id: "4", title: "Only options (Car)", credits: 0 },
    { id: "7", title: "TCU Tuning", credits: 1.0 },
    { id: "8", title: "Only options (TCU)", credits: 0 },
    { id: "9", title: "Multimap switch", credits: 5},
    { id: "10", title: "Back to stock", credits: 0.5 },
    { id: "12", title: "Tuning file review", credits: 0.2 },
  ];

  const tuningOptionsMap = {
    1: { label: "AdBlue", credits: 1 },
    2: { label: "Anti Lag", credits: 1 },
    3: { label: "Cat Heating", credits: 0.2 },
    4: { label: "Cylinder on Demand off", credits: 0.2 },
    5: { label: "Decat / O2", credits: 0 },
    6: { label: "DPF off", credits: 0 },
    7: { label: "DSG Farts", credits: 0.5 },
    8: { label: "DTC off", credits: 0.2 },
    9: { label: "E85 Conversion", credits: 2 },
    10: { label: "EGR off", credits: 0 },
    11: { label: "Evaporative Emission Control System", credits: 0.2 },
    12: { label: "Exhaust Flaps", credits: 0.2 },
    13: { label: "Hard Cut limiter (diesel)", credits: 0.5 },
    14: { label: "Hot Start", credits: 0.2 },
    15: { label: "Idle RPM", credits: 0.2 },
    16: { label: "Immo off", credits: 1 },
    17: { label: "Injector scaling", credits: 0.7 },
    18: { label: "Launch Control", credits: 0.5 },
    19: { label: "Limited Vmax to specific speed", credits: 0.5 },
    20: { label: "MAF off (if possible)", credits: 0.2 },
    21: { label: "Neutral RPM", credits: 0.2 },
    22: { label: "NOx off", credits: 0.5 },
    23: { label: "OPF off", credits: 0 },
    24: { label: "Performance Gauge BMW/Mini/VAG", credits: 0 },
    25: { label: "Pop & Bang / Crackle map", credits: 1 },
    26: { label: "Readiness Monitor", credits: 0.5 },
    27: { label: "Rev Limiter", credits: 0 },
    28: { label: "Secundairy Air Pump", credits: 0.2 },
    29: { label: "Start / Stop system off", credits: 0.2 },
    30: { label: "Swirl Flaps off", credits: 0.2 },
    31: { label: "Torque Monitoring off", credits: 0 },
    32: { label: "Vmax off", credits: 0 },
    33: { label: "Warranty Patch (BMW/Mini/VAG)", credits: 0.5 },
  };

  if (active === 1) {
    return (
      <Card>
        <div className="form_bg_section">
          <div className="text-center">
            <i className="bi bi-file-earmark display-5"></i>
            <h3 className="my-2">My tuning</h3>
            <p>
              Select your vehicle information to start your new file service
              request
            </p>
          </div>
        </div>
      </Card>
    );
  }

  if (!vehicleInfoData) {
    return (
      <Card>
        <div className="p-3">
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </Card>
    );
  }

  const getTotalCredits = (tuningIds) => {
    return tuningIds.reduce((total, id) => {
      return total + (tuningOptionsMap[id]?.credits || 0);
    }, 0);
  };
  const tuningTypeCredits = vehicleInfoData.tuning_type
    ? tuningTypes.find((type) => type.id === vehicleInfoData.tuning_type)?.credits || 0
    : 0;
  const additionalTuningCredits = additionalTuning ? getTotalCredits(additionalTuning) : 0;
  const grandTotalCredits = tuningTypeCredits + additionalTuningCredits;

  return (
    <Card>
      <div className="p-3">
        <VehicleSummary vehicleData={vehicleInfoData} />

        {active > 2 && (
          <div className="mt-3">
            <table className="table table-borderless">
              <tbody>
                {vehicleInfoData.read_method && (
                  <tr>
                    <th>Read Method</th>
                    <td> <span className="word-break">
                      {vehicleInfoData.read_method === "otherwise,namely"
                        ? vehicleInfoData.other_read
                        : vehicleInfoData.read_method}
                        </span>
                    </td>
                  </tr>
                )}
                {vehicleInfoData.software_no && (
                  <tr>
                    <th>Software Number</th>
                    <td> <span className="word-break">{vehicleInfoData.software_no}</span></td>
                  </tr>
                )}
                {vehicleInfoData.hardware_no && (
                  <tr>
                    <th>Hardware Number</th>
                    <td> <span className="word-break">{vehicleInfoData.hardware_no}</span></td>
                  </tr>
                )}
                {vehicleInfoData.tuning_type && (
                  <div className="mt-3">
                    <th>Tuning Type</th>
                    <div className="table-responsive">
                      <table className="table table-sm">
                        <thead>
                          <tr></tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {tuningTypes.find(
                                (type) =>
                                  type.id === vehicleInfoData.tuning_type
                              )?.title || `Type ${vehicleInfoData.tuning_type}`}
                            </td>
                            <td className="text-end">
                              {tuningTypes.find(
                                (type) =>
                                  type.id === vehicleInfoData.tuning_type
                              )?.credits === 0
                                ? "Free"
                                : `+${
                                    tuningTypes.find(
                                      (type) =>
                                        type.id === vehicleInfoData.tuning_type
                                    )?.credits || 0
                                  } credits`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        )}

        {active > 3 && additionalTuning && additionalTuning.length > 0 && (
          <div className="mt-0">
            <th>Additional Tuning</th>
            <div className="table-responsive">
              <table className="table table-sm">
                <thead>
                  <tr></tr>
                </thead>
                <tbody>
                  {additionalTuning.map((tuningId) => (
                    <tr key={tuningId}>
                      <td>
                        {tuningOptionsMap[tuningId]?.label ||
                          `Option ${tuningId}`}
                      </td>
                      <td className="text-end">
                        {tuningOptionsMap[tuningId]?.credits === 0
                          ? "Free"
                          : `+${
                              tuningOptionsMap[tuningId]?.credits || 0
                            } credits`}
                      </td>
                    </tr>
                  ))}
                  <tr className="table-light">
                    <td className="fw-bold">Total Additional Credits</td>
                    <td className="text-end fw-bold">
                      {getTotalCredits(additionalTuning)} credits
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Grand Total Credits Summary */}
        {active > 3 && (
          <div className="mt-3 mb-3">
            <div className="table-responsive">
              <table className="table table-sm table-bordered">
                <tbody>
                  <tr className="table-primary">
                    <td className="fw-bold">Total Credits</td>
                    <td className="text-end fw-bold">
                      {grandTotalCredits} credits
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        {active > 4 &&
          (vehicleInfoData.files?.length > 0 ||
            vehicleInfoData.optional_attachment_files?.length > 0) && (
            <div className="mt-3">
              <h6 className="mb-2">Files</h6>
              <ul className="list-unstyled">
                {vehicleInfoData.files?.map((file, index) => (
                  <li key={`main-${index}`}>{file.filename}</li>
                ))}
                {vehicleInfoData.optional_attachment_files?.map(
                  (file, index) => (
                    <li key={`optional-${index}`}>{file.filename}</li>
                  )
                )}
              </ul>
            </div>
          )}
      </div>
    </Card>
  );
};

export default RightSideCard;