import React, { useState,useEffect } from "react";
import Wrapper from "./Wrapper";
import TopCrumb from "../header/TopCrumb";
import { Alert, Card, Form } from "react-bootstrap";
import FileUploadContainer from "../ui/FileUploader";
import { useNavigate } from "react-router-dom";
import { createTickets,getFormList } from "../../api";

const CreateTicket = () => {
  const read_method_options = [
    { label: "Alientech K-TAG Bench", value: "2" },
    { label: "Alientech K-TAG Bootmode", value: "1" },
    { label: "Alientech KESS3 Bench", value: "5" },
    { label: "Alientech KESS3 Bootmode", value: "4" },
    { label: "Alientech KESS3 OBD", value: "6" },
    { label: "Alientech KESSv2 OBD", value: "3" },
    { label: "AMT Cartech Limited MPPS Bench", value: "51" },
    { label: "AMT Cartech Limited MPPS Bootmode", value: "50" },
    { label: "AMT Cartech Limited MPPS OBD", value: "52" },
    { label: "Autotuner Bench", value: "8" },
    { label: "Autotuner Bootmode", value: "7" },
    { label: "Autotuner OBD", value: "9" },
    { label: "bFlash Bench", value: "11" },
    { label: "bFlash Bootmode", value: "10" },
    { label: "bFlash OBD", value: "12" },
    { label: "Bitbox Bench", value: "14" },
    { label: "Bitbox Bootmode", value: "13" },
    { label: "Bitbox OBD", value: "15" },
    { label: "ByteShooter Toolbox Bench", value: "20" },
    { label: "ByteShooter Toolbox Bootmode", value: "19" },
    { label: "ByteShooter Toolbox OBD", value: "21" },
    { label: "CMDFlash Bench", value: "17" },
    { label: "CMDFlash Bootmode", value: "16" },
    { label: "CMDFlash OBD", value: "18" },
    { label: "DFB Technology DFOX Bench", value: "73" },
    { label: "DFB Technology DFOX Bootmode", value: "74" },
    { label: "DFB Technology DFOX OBD", value: "75" },
    { label: "Dimtronic Diamond Bench", value: "70" },
    { label: "Dimtronic Diamond Bootmode", value: "71" },
    { label: "Dimtronic Diamond OBD", value: "72" },
    { label: "EVC Electronic BDM100 Bootmode", value: "37" },
    { label: "EVC Electronic BSL100 Bootmode", value: "38" },
    { label: "Femto OBD Flasher OBD", value: "39" },
    { label: "FG Technology EOBD2 Bench", value: "41" },
    { label: "FG Technology EOBD2 Bootmode", value: "40" },
    { label: "FG Technology EOBD2 OBD", value: "42" },
    { label: "Frieling Racing IBOOT Bootmode", value: "44" },
    { label: "Frieling Racing IFLASH OBD", value: "43" },
    { label: "Frieling Racing SPI Wizard Bench", value: "46" },
    { label: "Frieling Racing SPI Wizard Bootmode", value: "45" },
    { label: "Frieling Racing SPI Wizard OBD", value: "47" },
    { label: "Green Technology Devices VF2 Flasher Bench", value: "32" },
    { label: "Green Technology Devices VF2 Flasher Bootmode", value: "31" },
    { label: "Green Technology Devices VF2 Flasher OBD", value: "33" },
    { label: "HP Tuners MPVI2 Bench", value: "48" },
    { label: "HP Tuners MPVI2 OBD", value: "49" },
    { label: "I/O Terminal Bench", value: "76" },
    { label: "I/O Terminal Bootmode", value: "77" },
    { label: "I/O Terminal OBD", value: "78" },
    { label: "MagicMotorsport Flex Bench", value: "29" },
    { label: "MagicMotorsport Flex Bootmode", value: "28" },
    { label: "MagicMotorsport Flex OBD", value: "30" },
    { label: "MagicMotorsport MAGPro2 Bootmode", value: "59" },
    { label: "MagicMotorsport MAGPro2 OBD", value: "60" },
    { label: "New Genius OBD", value: "24" },
    { label: "New Trasdata Bench", value: "26" },
    { label: "New Trasdata Bootmode", value: "25" },
    { label: "PCM-Flash Bench", value: "35" },
    { label: "PCM-Flash Bootmode", value: "34" },
    { label: "PCM-Flash OBD", value: "36" },
    { label: "Piasini Engineering Serial Suite Bench", value: "54" },
    { label: "Piasini Engineering Serial Suite Bootmode", value: "53" },
    { label: "Piasini Engineering Serial Suite OBD", value: "55" },
    { label: "TGFlash Bench", value: "79" },
    { label: "TGFlash Bootmode", value: "80" },
    { label: "TGFlash OBD", value: "81" },
    { label: "Otherwise, namely", value: "other" },
  ];

  const [data, setData] = useState({
    ticket_type: "",
    file_service: "",
    mileage: "",
    problem: "",
    other_problem: "",
    evc: "",
    reseller_file: "",
    read_method: "",
    other_method: "",
    match: "",
    subject: "",
    description: "",
    attachments: { file: [], additional: [] },
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [vehicleOptions, setVehicleOptions] = useState([]); // State for vehicle options
  console.log("🚀 ~ CreateTicket ~ vehicleOptions:", vehicleOptions)

  // Fetch vehicle makes when component mounts
  useEffect(() => {
    const fetchVehicleMakes = async () => {
      try {
        const token = localStorage.getItem("Access-Token");
        if (!token) return;

        const response = await getFormList(token);    
        if (response && response.status_code === 200 && Array.isArray(response.data)) {
          // Group by make to avoid duplicates
          const uniqueMakes = {};
          response.data.forEach(item => {
            if (item.make && item.make.id && item.make.name) {
              uniqueMakes[item.make.id] = item.make;
            }
          });
          
          // Convert to array for select options
          const makeOptions = Object.values(uniqueMakes);
          setVehicleOptions(makeOptions);
        }
      } catch (err) {
        console.error("Error fetching vehicle makes:", err);
      }
    };

    fetchVehicleMakes();
  }, []);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const editAttachments = (index) => {
    let array = data.attachments.filter((attachment, i) => i !== index);
    setData({ ...data, attachments: array });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    
    try {
      // Get the auth token
      const token = localStorage.getItem("Access-Token");
      
      // Get attachment IDs from both primary and additional files if they exist
    // In your handleSubmit function, modify the attachment ID extraction:
    let attachments = [];
    
    if (data.attachments.file && data.attachments.file.length > 0) {
      attachments = [...attachments, ...data.attachments.file.map(file => file.url)];
    }
    
    if (data.attachments.additional && data.attachments.additional.length > 0) {
      attachments = [...attachments, ...data.attachments.additional.map(file => file.url)];
    }
    
    // Prepare ticket data
    const ticketData = {
      ticket_type: data.ticket_type,
      desc: data.description,
      // Replace attachment_ids with attachments
      attachments: attachments,
    };
    
      // Add specific fields based on ticket type
      if (data.ticket_type === "file-service") {
        ticketData.file_list = data.file_service;
        ticketData.file_mileage = data.mileage;
        ticketData.file_describe = data.problem;
        if (data.problem === "other") {
          ticketData.other_problem = data.other_problem;
        }
      } else if (data.ticket_type === "evc") {
        ticketData.reseller_file = data.reseller_file;
        ticketData.read_method = data.read_method;
        if (data.read_method === "other") {
          ticketData.other_method = data.other_method;
        }
        ticketData.file_mileage = data.mileage;
        ticketData.file_describe = data.problem;
        ticketData.evc_match = data.match;
      } else if (data.ticket_type === "general") {
        ticketData.gi_subject = data.subject;
      }
      
      // Create the ticket
      const response = await createTickets(ticketData, token);
      
      if (response && response.success) {
        setSuccess("Ticket created successfully!");
        setTimeout(() => {
          navigate("/support-tickets");
        }, 2000);
      } else {
        throw new Error(response.message || "Failed to create ticket");
      }
    } catch (err) {
      console.error("Error submitting ticket:", err);
      setError(err.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <TopCrumb route={"Create support ticket"} />
      <div className="row my-1 p-3">
        <div className="col-md-12">
          <Card className="p-4 col-md-8 col-lg-8">
            <div className="Dashboard_heading_2">
              <h4 className="Dashboard_heading_1">Support Ticket</h4>
              <svg
                className="icon_arrow"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
              </svg>
            </div>
            
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="ticket_type" className="mb-2">
                  Ticket type
                </label>
                <Form.Select
                  id="ticket_type"
                  name="ticket_type"
                  value={data.ticket_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select ticket type</option>
                  <option value="file-service">File service</option>
                  <option value="evc">EVC reseller database</option>
                  <option value="general">General question</option>
                </Form.Select>
              </div>
              {data.ticket_type === "file-service" && (
                <>
                  <div className="mb-3">
                    <label htmlFor="file_service" className="mb-2">
                      File service
                    </label>
                    <Form.Select
                      id="file_service"
                      name="file_service"
                      value={data.file_service}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select file service</option>
                      {vehicleOptions.map((make) => (
                        <option key={make.id} value={make.id}>
                          {make.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
    
                  <div className="mb-3">
                    <label htmlFor="mileage" className="mb-2">
                      What is the mileage of the vehicle?
                    </label>
                    <Form.Control
                      type="text"
                      id="mileage"
                      name="mileage"
                      value={data.mileage}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="problem" className="mb-2">
                      Describe Problem
                    </label>
                    <Form.Select
                      id="problem"
                      name="problem"
                      value={data.problem}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select the issue</option>
                      <option value="start">Car does not start</option>
                      <option value="results">
                        The specified gains are not achieved
                      </option>
                      <option value="options">
                        Additional option(s) not working
                      </option>
                      <option value="errors">Error codes shown</option>
                      <option value="limp">Car is in limp mode</option>
                      <option value="encoding">File encoding issue</option>
                      <option value="other">Otherwise, namely</option>
                    </Form.Select>
                  </div>
                  {data.problem === "other" && (
                    <div className="mb-3">
                      <label htmlFor="other_problem" className="mb-2">
                        Please specify
                      </label>
                      <Form.Control
                        type="text"
                        id="other_problem"
                        name="other_problem"
                        value={data.other_problem}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </>
              )}
              {data.ticket_type === "evc" && (
                <>
                  <div className="mb-3">
                    <label htmlFor="reseller_file" className="mb-2">
                      Evc reseller file
                    </label>
                    <Form.Select
                      id="reseller_file"
                      name="reseller_file"
                      value={data.reseller_file}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select file</option>
                      {/* Add your options here */}
                    </Form.Select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="read_method" className="mb-2">
                      Read method
                    </label>
                    <Form.Select
                      id="read_method"
                      name="read_method"
                      value={data.read_method}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Make your choice</option>
                      {read_method_options.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  {data.read_method === "other" && (
                    <div className="mb-3">
                      <label htmlFor="other_method" className="mb-2">
                        Please specify
                      </label>
                      <Form.Control
                        type="text"
                        id="other_method"
                        name="other_method"
                        value={data.other_method}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  <div className="mb-3">
                    <label htmlFor="mileage" className="mb-2">
                      What is the mileage of the vehicle?
                    </label>
                    <Form.Control
                      type="text"
                      id="mileage"
                      name="mileage"
                      value={data.mileage}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="problem" className="mb-2">
                      Describe Problem
                    </label>
                    <Form.Select
                      id="problem"
                      name="problem"
                      value={data.problem}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select the problem</option>
                      <option value="writing">
                        Problems occur when writing/programming/DTC/Limp mode of
                        the file into the vehicle.
                      </option>
                      <option value="power">
                        There are troubles with the power output.
                      </option>
                    </Form.Select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="match" className="mb-2">
                      Is the file a 100% match?
                    </label>
                    <Form.Select
                      id="match"
                      name="match"
                      value={data.match}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select the option</option>
                      <option value="yes">
                        The WinOLS file was a 100% match.
                      </option>
                      <option value="no">
                        I have copied the adjustments in the WinOLS project to
                        my Tuning file.
                      </option>
                      <option value="solutions_project">
                        No, it is an EVC Solutions project.
                      </option>
                    </Form.Select>
                  </div>
                </>
              )}
              {data.ticket_type === "general" && (
                <div className="mb-3">
                  <label htmlFor="subject" className="mb-2">
                    Subject
                  </label>
                  <Form.Control
                    type="text"
                    id="subject"
                    name="subject"
                    value={data.subject}
                    onChange={handleChange}
                    required
                  />
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="description" className="mb-2">
                  Description <small>(as specific as possible)</small>
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  value={data.description}
                  onChange={handleChange}
                  rows="3"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="attachments">
                  Attachments <small>(optional)</small>
                </label>
                <br />
                <small className="text-secondary">max size 20MB</small>
                <div className="mt-2 mb-3">
                <FileUploadContainer
            attachments={data.attachments}
            handleFiles={(attachments) => setData({ ...data, attachments })}
          />
        </div>
                {data.attachments?.length > 0 && (
                  <>
                    {data.attachments.map((attachment, index) => (
                      <Alert key={index} className="py-2 my-2">
                        {attachment.name}
                        <button
                          type="button"
                          className="btn-none float-end p-0"
                          onClick={() => editAttachments(index)}
                        >
                          <i className="bi bi-x fs-5 text-danger"></i>
                        </button>
                      </Alert>
                    ))}
                  </>
                )}
              </div>
              <div className="">
                <button
                  type="button"
                  className="btn btn-outline-primary me-2"
                  onClick={() => navigate("/support-tickets")}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="btn btn-light-info"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    </Wrapper>
  );
};

export default CreateTicket;