import React from "react";

const VehicleSummary = ({ active, vehicleData, vehicleInfoData }) => {
  console.log("🚀 ~ VehicleSummary ~ vehicleData:", vehicleData)
  if (!vehicleData) return null;

  const toTitleCase = (str) => {
    if (!str || typeof str !== "string") return "";
    return str
      .toLowerCase()
      .split(/[\s-]/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace(/\b(\w+)-(\w+)\b/g, (match, p1, p2) => `${p1}-${p2.charAt(0).toUpperCase()}${p2.slice(1)}`);
  };

  const getName = (field, otherField) => {
    if (field === "otherwise,namely") return toTitleCase(otherField);
    if (typeof field === "object" && field?.name) return toTitleCase(field.name);
    return toTitleCase(field);
  };

  return (
    <div className="p-3">
      <h5 className="mb-3">Vehicle Information</h5>

      <h6>
        {getName(vehicleData.make, vehicleData.other_make)}{" "}
        {getName(vehicleData.model, vehicleData.other_model)}
      </h6>
      <p>
        {getName(vehicleData.generation, vehicleData.other_generation)}{" "}
        {getName(vehicleData.engine, vehicleData.other_engine)}
      </p>

      <tr>
        <th>ECU</th>
        <td>
          <span className="word-break">
            {getName(vehicleData.ecu, vehicleData.other_ecu)}
          </span>
        </td>
      </tr>

      <tr>
        <th>Gearbox</th>
        <td>{getName(vehicleData.gearbox)}</td>
      </tr>

      {(vehicleData.engine_hp || vehicleData.engine_kw) && (
        <tr>
          <th>Engine Power</th>
          <td>
            <span className="word-break">
              {vehicleData.engine_hp && `${toTitleCase(vehicleData.engine_hp)} HP`}
              {vehicleData.engine_hp && vehicleData.engine_kw && " / "}
              {vehicleData.engine_kw && `${toTitleCase(vehicleData.engine_kw)} kW`}
            </span>
          </td>
        </tr>
      )}

      {vehicleData.vin_number && (
        <tr>
          <th>VIN Number</th>
          <td>
            <span className="word-break">
              {toTitleCase(vehicleData.vin_number)}
            </span>
          </td>
        </tr>
      )}
    </div>
  );
};

export default VehicleSummary;
