import React, { useState, useEffect } from "react";
import { authClient } from "../../lib/client";
import { sendEngineData } from "../../api";
import { useNavigate } from "react-router-dom";

const ModelSearchFormSecond = () => {
  const navigate = useNavigate();
  const [AccessToken, setAccessToken] = useState("");
  const [vehiclesData, setVehiclesData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [generationData, setGenerationData] = useState([]);
  const [engineData, setEngineData] = useState([]);
  const [selectedIds, setSelectedIds] = useState({
    vehicle_id: '',
    model_id: '',
    generation_id: '',
    engine_id: ''
  });

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(/[\s-]/) 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace(/\b(\w+)-(\w+)\b/g, (match, p1, p2) => `${p1}-${p2.charAt(0).toUpperCase()}${p2.slice(1)}`);
  };

  useEffect(() => {
    const access_token = localStorage.getItem("Access-Token");
    setAccessToken(access_token);
    getVehicles(access_token);
  }, []);

  const getVehicles = async (token) => {
    const response = await authClient.GetVehicles(token);
    setVehiclesData(response.data);
  };

  const handleChangeCar = async (event) => {
    const vehicleId = event.target.value;
    setSelectedIds(prev => ({ ...prev, vehicle_id: vehicleId }));
    const response = await authClient.GetModels(vehicleId, AccessToken);
    setModelData(response.data);
    setGenerationData([]);
    setEngineData([]);
  };

  const handleChangeCarModal = async (event) => {
    const modelId = event.target.value;
    setSelectedIds(prev => ({ ...prev, model_id: modelId }));
    const response = await authClient.GetModelGeneration(modelId, AccessToken);
    setGenerationData(response.data);
    setEngineData([]);
  };

  const handleChangeCarGeneration = async (event) => {
    const generationId = event.target.value;
    setSelectedIds(prev => ({ ...prev, generation_id: generationId }));
    const response = await authClient.GetModelGenerationEngine(generationId, AccessToken);
    if (response?.data?.data?.null && Array.isArray(response.data.data.null)) {
      setEngineData(response.data.data.null);
    } else {
      setEngineData([]);
    }
  };

  const handleEngineChange = (event) => {
    const engineId = event.target.value;
    setSelectedIds(prev => ({ ...prev, engine_id: engineId }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!selectedIds.generation_id || !selectedIds.engine_id) {
        window.location.reload();
        return;
      }
  
      const response = await sendEngineData(selectedIds, AccessToken);

      if (
        !response?.power_object || Object.keys(response.power_object)?.length === 0 ||
        !response?.torque_object || Object.keys(response.torque_object)?.length === 0 ||
        !response?.engine_details || Object.keys(response.engine_details)?.length === 0
      ) {
        window.location.reload();
        return;
      }
  
      navigate("/chiptuningfile", {
        state: { engineData: response },
      });
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <form action="" className="from_car shadow-lg ">
      <h1 className="pragraph_alientech_5 my-2">
        View all our{" "}
        <a href="" className="Alientech_link_1">
          chiptuning files
        </a>{" "}
        specifications online
      </h1>
      <div className="select_tab_alienech">
        <select
          name=""
          id=""
          className="Choose_tab"
          onChange={handleChangeCar}
        >
          <option>Choose a make</option>
          {vehiclesData?.map((vehicle) => {
            return (
              <option key={vehicle._id} value={vehicle._id}>
                {toTitleCase(vehicle.car_name)}
              </option>
            );
          })}
        </select>
        <select
          className="Choose_tab"
          onChange={handleChangeCarModal}
        >
          <option>Choose a model</option>
          {modelData?.map((model) => {
            return (
              <option key={model._id} value={model._id}>
                {toTitleCase(model.model_name)}
              </option>
            );
          })}
        </select>
      </div>
      <div className="select_tab_alienech responsive_choose">
        <select
          className="Choose_tab"
          onChange={handleChangeCarGeneration}
        >
          <option>Choose a generation</option>
          {generationData?.map((generation) => {
            return (
              <option key={generation._id} value={generation._id}>
                {toTitleCase(generation.generation_name)}
              </option>
            );
          })}
        </select>
        <select 
          className="Choose_tab"
          onChange={handleEngineChange}
          value={selectedIds.engine_id}
          disabled={!selectedIds.generation_id}
        >
          <option>Choose an engine</option>
          {engineData?.map((engine) => {
            return (
              <option key={engine._id} value={engine._id}>
                {toTitleCase(engine.engine_name)}
              </option>
            );
          })}
        </select>
      </div>
      <button className="Start_Seaching my-3 shadow-lg" type="submit" onClick={handleSubmit}>
        Start Searching
      </button>
    </form>
  );
};

export default ModelSearchFormSecond;