import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  getOriVehicles,
  getSeries,
  getOriEngine,
  sendCarEngineData,
  EngineDetails,
  getAllOriFiles,
} from "../../api";
import MainHeader from "../header/Header";
import MainFooter from "../footer/Footer";
import "../../assets/css/alientechtools.css";
import car from "../../assets/images/car.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ModelSearchFormSecond from "./ModelSearchFormSecond";
import { useVehicleContext } from "./vehicleContext";

const Original = () => {
  const resultsPerPage = 100; 
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  console.log("🚀 ~ Original ~ totalPages:", totalPages)
  const [activeSearch, setActiveSearch] = useState(null);
  const [searchValues, setSearchValues] = useState({
    hardware: "",
    software: "",
    ecuType: "",
  });

  const {
    vehicles,
    setVehicles,
    series,
    setSeries,
    engines,
    setEngines,
    selectedVehicle,
    setSelectedVehicle,
    selectedSeries,
    setSelectedSeries,
    selectedEngine,
    setSelectedEngine,
    ecuData,
    setEcuData,
  } = useVehicleContext();
  const searchTimer = React.useRef(null);
  const [loading, setLoading] = useState(true); 
  const [allFilesMode, setAllFilesMode] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataFetched, setDataFetched] = useState(false); 
  const [isInitializing, setIsInitializing] = useState(true);
  const navigate = useNavigate();
  const token = "your-auth-token";

  const toTitleCase = (str = "") => {
    if (typeof str !== "string") return "";
    return str
      .toLowerCase()
      .split(/[\s-]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace(
        /\b(\w+)-(\w+)\b/g,
        (match, p1, p2) => `${p1}-${p2.charAt(0).toUpperCase()}${p2.slice(1)}`
      );
  };

  const getLast5Chars = (str) => {
    if (!str || typeof str !== "string") return "";
    return str.length >= 5 ? str.slice(-5) : str;
  };
  const fetchAllFiles = async (page = 1, searchKey = null, searchValue = null) => {
    setLoading(true);
    try {
      // Create search params object
      const searchParams = {};
      const isFiltering = searchKey && searchValue && searchValue.length >= 3;
      
      if (isFiltering) {
        searchParams.key = searchKey;
        searchParams.value = searchValue;
      }
      
      const allFilesResponse = await getAllOriFiles(token, page, searchParams);
      if (allFilesResponse?.status_code === 200) {
        const totalCount = allFilesResponse.filter_total_count || 0;
        
     
        const pagesToUse = isFiltering && allFilesResponse.total_pages !== undefined
       
          ? allFilesResponse.filter_total_pages
          : allFilesResponse.total_pages || 1;
        const responseData = Array.isArray(allFilesResponse.data)
          ? allFilesResponse.data
          : [];
          
        setEcuData(responseData);
        setTotalRecords(totalCount);
        setTotalPages(pagesToUse);
        setCurrentPage(page);
        setAllFilesMode(true);
        setDataFetched(true);
      } else {
        console.error("Unexpected response structure:", allFilesResponse);
        setEcuData([]);
      }
    } catch (error) {
      console.error("Error fetching paginated data:", error);
      toast.error("Failed to load data. Please try again.");
      setEcuData([]);
    } finally {
      setLoading(false);
    }
  };
  const fetchSearchResults = async () => {
    setLoading(true);
  
    try {
      const selectedCar =
        vehicles.find((v) => v._id === selectedVehicle)?._id || "";
      const selectedSeriesName =
        series.find((s) => s._id === selectedSeries)?._id || "";
      const selectedEngineName =
        engines.find((e) => e._id === selectedEngine)?._id || "";
  
      const response = await sendCarEngineData(
        { car_name: selectedCar, series_name: selectedSeriesName, engine_name: selectedEngineName },
        token
      );
      setAllFilesMode(false);
  
      if (!response || response?.status_code === 404) {
        toast.error(response?.error || "Error: Not Found");
        setEcuData([]);
        return;
      }
  
      if (Array.isArray(response.data) && response.data.length > 0) {
        setEcuData(response.data);
        setTotalPages(Math.ceil(response.data.length / resultsPerPage));
        setCurrentPage(1);
        setDataFetched(true);
      } else {
        setEcuData([]); 
        setDataFetched(true);
      }
    } catch (error) {
      console.error("❌ Error in search process:", error);
      toast.error("An unexpected error occurred. Please try again.");
      setEcuData([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const initializeComponent = async () => {
      setIsInitializing(true);
      try {
        if (vehicles.length === 0) {
          const vehiclesData = await getOriVehicles(token);
          setVehicles(vehiclesData);
        }
        if (selectedVehicle && selectedSeries && selectedEngine) {
          await fetchSearchResults();
        } else {
          await fetchAllFiles(1);
        }
      } catch (error) {
        console.error("Error in initialization:", error);
        toast.error("Failed to initialize. Please refresh the page.");
      } finally {
        setLoading(false);
        setIsInitializing(false);
      }
    };
  
    initializeComponent();
  }, []);
  useEffect(() => {
    const loadSavedSelections = async () => {
      if (selectedVehicle && series.length === 0) {
        try {
          const seriesData = await getSeries(selectedVehicle, token);
          setSeries(seriesData);

          if (selectedSeries && engines.length === 0) {
            const engineResponse = await getOriEngine(selectedSeries, token);
            if (
              engineResponse?.status_code === 200 &&
              Array.isArray(engineResponse?.data)
            ) {
              setEngines(engineResponse.data);
            }
          }
        } catch (error) {
          console.error("Error loading saved selections:", error);
        }
      }
    };

    loadSavedSelections();
  }, [selectedVehicle, selectedSeries]);

  const handleVehicleChange = async (vehicle_id) => {
    setSelectedVehicle(vehicle_id);
    setSelectedSeries("");
    setSelectedEngine("");
    setSeries([]);
    setEngines([]);

    try {
      const data = await getSeries(vehicle_id, token);
      setSeries(data);
    } catch (error) {
      console.error("Error fetching series:", error);
    }
  };

  const handleSeriesChange = async (series_id) => {
    setSelectedSeries(series_id);
    setSelectedEngine("");
    setEngines([]);

    try {
      const response = await getOriEngine(series_id, token);
      if (response?.status_code === 200 && Array.isArray(response?.data)) {
        setEngines(response.data);
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error("Error fetching engines:", error);
    }
  };

  const processTableData = (item) => {
    const fileSpec = item?.all_fields?.file_specification || {};
    const fieldId = item?.all_fields?.field_id || item?._id || "";
    const fieldIdLast5 = getLast5Chars(fieldId);
    
    const hardwareNr = fileSpec["Hardware nr"] || 
      fileSpec["Hardware number"] || 
      fileSpec["hardware_nr"] || 
      fileSpec["hardware_number"] || 
      "";
    
      const urlParams = [
        fileSpec["ECU manufacturer"],
        fileSpec["ECU name"],
        fileSpec["ECU prod nr"]
          ? fileSpec["ECU prod nr"].replace(/#/g, "-hash-") 
          : "NA",
        fileSpec["Hardware nr"],
        fileSpec["Software"],
        fieldIdLast5, 
      ]
        .map((param) =>
          param
            ? param.replace(/_/g, "-ud-").replace(/\//g, "-sh-")
            : "NA"
        )
       
    const carName = fileSpec["Car"] || 
      (item?.make?.name && item?.model?.name) 
        ? `${(item.make.name)} ${(item.model.name)}`
        : "";
    
    const engineName = fileSpec["Type"] !== "-" ? fileSpec["Type"] : item?.engine?.name || "";
  
    return {
      carName,
      engineName,
      hardwareNr,
      software: fileSpec["Software"] || "",
      ecuManufacturer: fileSpec["ECU manufacturer"] || "",
      ecuName: fileSpec["ECU name"] || "",
      softwareSize: fileSpec["Software size"] || "",
      urlParams: urlParams.join("_"),
    };
  };
  const filteredData = useMemo(() => {
    if (!ecuData || ecuData.length === 0) return [];
  
    const results = ecuData.filter((item) => {
      const processedData = processTableData(item);
      const { hardware, software, ecuType } = searchValues;
  
      const normalizeString = (str) => 
        str ? str.toLowerCase().replace(/[\s-]/g, '') : '';
  
      const matchesSearch = (value, searchTerm) => {
        if (!searchTerm) return true;
        const normalizedValue = normalizeString(value);
        const normalizedSearchTerm = normalizeString(searchTerm);
        return normalizedValue.includes(normalizedSearchTerm);
      };
  
      const hardwareMatch = matchesSearch(processedData.hardwareNr, hardware);
      const softwareMatch = matchesSearch(processedData.software, software);
      const ecuTypeMatch = matchesSearch(processedData.ecuName, ecuType);
  
      return hardwareMatch && softwareMatch && ecuTypeMatch;
    });

    return results;
  }, [ecuData, searchValues]);
  const paginatedData = useMemo(() => {
    if (allFilesMode) return ecuData;
    const startIndex = (currentPage - 1) * resultsPerPage;
    const endIndex = startIndex + resultsPerPage;
    
    return filteredData.slice(startIndex, endIndex);
  }, [allFilesMode, filteredData, currentPage, resultsPerPage]);
useEffect(() => {
  if (!allFilesMode) {
    const newTotalPages = Math.ceil(filteredData.length / resultsPerPage);
    setTotalPages(newTotalPages);

    if (currentPage > newTotalPages) {
      setCurrentPage(1);
    }
  }
}, [filteredData, allFilesMode, resultsPerPage]);

  const toggleSearch = (field) => {
    setActiveSearch(activeSearch === field ? null : field);
  };

  const handleChange = (field, value) => {
    setSearchValues((prev) => ({
      ...prev,
      [field]: value,
    }));
    
    if (allFilesMode) {
      clearTimeout(searchTimer.current);
      
      if (value.length >= 3 || value === '') {
        searchTimer.current = setTimeout(() => {
          let searchKey = null;
          if (field === 'hardware') searchKey = 'Hardware nr';
          else if (field === 'software') searchKey = 'Software';
          else if (field === 'ecuType') searchKey = 'ECU name';
          
          fetchAllFiles(1, searchKey, value);
        }, 500);
      }
    } else {
      setCurrentPage(1);
    }
  };
  
  const clearSearch = (field) => {
    setSearchValues(prev => ({
      ...prev,
      [field]: '',
    }));
    setActiveSearch(null);
    
    if (allFilesMode) {
      fetchAllFiles(1);
    }
    
    setCurrentPage(1);
  };
  
  const handleSearch = async () => {
    setLoading(true);
  
    try {
      const selectedCar =
        vehicles.find((v) => v._id === selectedVehicle)?._id || "";
      const selectedSeriesName =
        series.find((s) => s._id === selectedSeries)?._id || "";
      const selectedEngineName =
        engines.find((e) => e._id === selectedEngine)?._id || "";
  
      const response = await sendCarEngineData(
        { car_name: selectedCar, series_name: selectedSeriesName, engine_name: selectedEngineName },
        token
      );
  
      setAllFilesMode(false);
  
      if (!response || response?.status_code === 404) {
        toast.error(response?.error || "Error: Not Found");
        setEcuData([]);
        return;
      }
  
      if (Array.isArray(response.data) && response.data.length > 0) {
        if (response.data.length > 1) {
          setEcuData(response.data);
          setTotalPages(Math.ceil(response.data.length / resultsPerPage));
          setCurrentPage(1);
          setDataFetched(true);
        } else {
          try {
            const fileSpec = response.data[0]?.all_fields?.file_specification || {};
            const fieldId = response.data[0]?.all_fields?.field_id;
            const fieldIdLast5 = getLast5Chars(fieldId);
            
            const payload = {
              ecu_manufacturer: fileSpec["ECU manufacturer"] || "",
              ecu_name: fileSpec["ECU name"] || "",
              ecu_prod_nr: fileSpec["ECU prod nr"] || "",
              hardware_nr: fileSpec["Hardware nr"] || "",
              software: fileSpec["Software"] || "",
              field_id: fieldIdLast5, 
            };
  
            const engineDetailsResponse = await EngineDetails(payload, token);
  
            if (!engineDetailsResponse || engineDetailsResponse?.status_code === 404) {
              toast.error("Could not find data for the selected vehicle configuration");
              setEcuData([]);
              return;
            }
  
            const urlParams = [
              fileSpec["ECU manufacturer"],
              fileSpec["ECU name"],
              fileSpec["ECU prod nr"]
                ? fileSpec["ECU prod nr"].replace(/#/g, "-hash-") 
                : "NA",
              fileSpec["Hardware nr"],
              fileSpec["Software"],
              fieldIdLast5, 
            ]
              .map((param) =>
                param
                  ? param.replace(/_/g, "-ud-").replace(/\//g, "-sh-")
                  : "NA"
              )
              .join("_"); 
            
  
            const finalUrl = `/tuning-ecu-files-database/${urlParams}`;
            navigate(finalUrl);
          } catch (engineError) {
            console.error("❌ Error fetching engine details:", engineError);
            toast.error("An error occurred while fetching engine details. Please try again.");
            setEcuData([]);
          }
        }
      } else {
        setEcuData([]);
        setDataFetched(true);
      }
    } catch (error) {
      console.error("❌ Error in search process:", error);
      toast.error("An unexpected error occurred. Please try again.");
      setEcuData([]);
    } finally {
      setLoading(false);
    }
  };
  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      if (allFilesMode) {
        // Get the current active search if any
        let searchKey = null;
        let searchValue = null;
        
        if (searchValues.hardware && searchValues.hardware.length >= 3) {
          searchKey = 'Hardware nr';
          searchValue = searchValues.hardware;
        } else if (searchValues.software && searchValues.software.length >= 3) {
          searchKey = 'Software';
          searchValue = searchValues.software;
        } else if (searchValues.ecuType && searchValues.ecuType.length >= 3) {
          searchKey = 'ECU name';
          searchValue = searchValues.ecuType;
        }
        
        fetchAllFiles(newPage, searchKey, searchValue);
      } else {
        setCurrentPage(newPage);
      }
    }
  };

  return (
    <>
      <MainHeader />

      <div className="container-fluid text-light bg-dark p-4">
        <h2 className="heading-text mb-4 mt-5">Tuning ECU Files Database</h2>

        <div className="row mb-3">
          <div className="col-md-3">
            <select
              className="form-select arrow-design transparent-select"
              onChange={(e) => handleVehicleChange(e.target.value)}
              value={selectedVehicle}
            >
              <option value="">Choose a make</option>
              {vehicles.map((vehicle) => (
                <option key={vehicle._id} value={vehicle._id}>
                  {(vehicle.car_name)}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <select
              className="form-select arrow-design transparent-select"
              onChange={(e) => handleSeriesChange(e.target.value)}
              disabled={!selectedVehicle}
              value={selectedSeries}
            >
              <option value="">Choose a model</option>
              {series.map((s) => (
                <option key={s._id} value={s._id}>
                  {(s.model_name)}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <select
              className="form-select arrow-design transparent-select"
              onChange={(e) => setSelectedEngine(e.target.value)}
              disabled={!selectedSeries}
              value={selectedEngine}
            >
              <option value="">Choose an engine</option>
              {engines.map((engine) => (
                <option key={engine._id} value={engine._id}>
                  {toTitleCase(engine.engine_name)}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <button
              className="btn btn--full btn--search"
              type="button"
              onClick={handleSearch}
              disabled={
                loading ||
                !selectedVehicle 
              }
            >
              {loading ? (
                "Searching..."
              ) : (
                <>
                  <i className="bi bi-search"></i> Find my car
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : paginatedData && paginatedData.length > 0 ? (
        <>
          <div className="table-responsive mt-5">
            <table className="table table-dark table-striped table-bordered">
              <thead>
                <tr>
                  <th>Make and Model</th>
                  <th>Engine</th>
                  
                  <th style={{ width: "15%" }}>
                    Hardware nr.
                    <div className="inline-block">
                      {activeSearch === "hardware" && (
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            value={searchValues.hardware}
                            onChange={(e) => handleChange("hardware", e.target.value)}
                            className="search-input form-control form-control-sm"
                            placeholder="Search hardware"
                          />
                          <button
                            type="button"
                            className="btn btn-sm btn-close btn-close-white ml-2"
                            onClick={() => clearSearch("hardware")}
                          ></button>
                        </div>
                      )}
                      <i
                        className="bi bi-search cursor-pointer"
                        onClick={() => toggleSearch("hardware")}
                      ></i>
                    </div>
                  </th>

                  <th>
                    Software nr.
                    <div className="inline-block">
                      {activeSearch === "software" && (
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            value={searchValues.software}
                            onChange={(e) => handleChange("software", e.target.value)}
                            className="search-input form-control form-control-sm"
                            placeholder="Search software"
                          />
                          <button
                            type="button"
                            className="btn btn-sm btn-close btn-close-white ml-2"
                            onClick={() => clearSearch("software")}
                          ></button>
                        </div>
                      )}
                      <i
                        className="bi bi-search cursor-pointer"
                        onClick={() => toggleSearch("software")}
                      ></i>
                    </div>
                  </th>

                  <th>ECU</th>

                  <th>
                    ECU Type
                    <div>
                      {activeSearch === "ecuType" && (
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            value={searchValues.ecuType}
                            onChange={(e) => handleChange("ecuType", e.target.value)}
                            className="search-input form-control form-control-sm"
                            placeholder="Search ECU Type"
                          />
                          <button
                            type="button"
                            className="btn btn-sm btn-close btn-close-white ml-2"
                            onClick={() => clearSearch("ecuType")}
                          ></button>
                        </div>
                      )}
                      <i
                        className="bi bi-search cursor-pointer"
                        onClick={() => toggleSearch("ecuType")}
                      ></i>
                    </div>
                  </th>

                  <th>Size</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((item, index) => {
                  const processedData = processTableData(item);
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        navigate(
                          `/tuning-ecu-files-database/${processedData.urlParams}`
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{processedData.carName}</td>
                      <td>{processedData.engineName}</td>
                      <td>{processedData.hardwareNr}</td>
                      <td>{processedData.software}</td>
                      <td>{processedData.ecuManufacturer}</td>
                      <td>{processedData.ecuName}</td>
                      <td>{processedData.softwareSize}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="pagination-container d-flex justify-content-between align-items-center">
  <span style={{color:"white"}}>
    Page {currentPage} of {totalPages}{" "}
    {allFilesMode && `(${totalRecords} total records)`}
  </span>
  <ul className="pagination pagination-dark">
    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
      <button className="page-link" onClick={() => changePage(1)}>
        «
      </button>
    </li>

    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
      <button className="page-link" onClick={() => changePage(currentPage - 1)}>
        ‹
      </button>
    </li>

    {/* Only create as many buttons as needed, up to 5 */}
    {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
      let pageNum;
      
      if (totalPages <= 5) {
        // If 5 or fewer total pages, show all pages
        pageNum = i + 1;
      } else if (currentPage <= 3) {
        // If near the start
        pageNum = i + 1;
      } else if (currentPage >= totalPages - 2) {
        // If near the end
        pageNum = totalPages - 4 + i;
      } else {
        // In the middle
        pageNum = currentPage - 2 + i;
      }

      // Only display buttons for valid pages
      if (pageNum <= totalPages) {
        return (
          <li
            key={i}
            className={`page-item ${currentPage === pageNum ? "active" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => changePage(pageNum)}
            >
              {pageNum}
            </button>
          </li>
        );
      }
      return null;
    }).filter(Boolean)}

    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
      <button className="page-link" onClick={() => changePage(currentPage + 1)}>
        ›
      </button>
    </li>

    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
      <button className="page-link" onClick={() => changePage(totalPages)}>
        »
      </button>
    </li>
  </ul>
</div>
        </>
      ) : (
        <div className="alert alert-info my-5 text-center">
          {dataFetched
            ? "No data available. Please select a make, model, and engine to search for ECU files."
            : "Failed to load data. Please try refreshing the page."}
        </div>
      )}

      <div className="container">
        <div className="row responsive_alientech">
          <div className="col-4 mt-5 responsive_tab">
            <img
              src={car}
              alt="car"
              width={500}
              height={300}
              className="box_image"
            />
          </div>
          <div className="col-8 my-5 responsive_tab_1">
            <ModelSearchFormSecond />
          </div>
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default Original;