import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Components/home/Home";
import Login from "../Components/home/login";
import Register from "../Components/home/register";
import Alientechtools from "../Components/home/alientechtools";
import Cmdflashtool from "../Components/home/cmdflashtool";
import Magicmotorsport from "../Components/home/magicmotorsport";
import Autotuner from "../Components/home/autotuner";
import Chiptuningfile from "../Components/home/chiptuningfile";
import Aboutus from "../Components/home/aboutus";
import Prices from "../Components/home/prices";
import Projects from "../Components/home/projects";
import Chiptuningbusiness from "../Components/home/chiptuningbusiness";
import Caroverveiw from "../Components/home/caroverveiw";
import Data from "../Components/home/data";
import Dashboard from "../Components/dashboard/dashboard";
import Navbar from "../Components/dashboard/navbar";
import Dashboardhome from "../Components/dashboard/dashboardhome";
import OriginalFiles from "../Components/dashboard/Files/OriginalFiles";
import CreditTransaction from "../Components/dashboard/CreditTransaction";
import Subscriptions from "../Components/dashboard/Subscriptions";
import DemoFiles from "../Components/dashboard/Files/DemoFiles";
import SupportTickets from "../Components/dashboard/SupportTickets";
import CreateTicket from "../Components/dashboard/CreateTicket";
import AddSubscription from "../Components/dashboard/Subscriptions/add-subscription";
import FileServices from "../Components/dashboard/FileServices";
import NewFileServices from "../Components/dashboard/FileServices/NewFileServices";
import OrderHistory from "../Components/dashboard/OrderHistory";
import ExportInvoice from "../Components/dashboard/ExportInvoice";
import Faq from "../Components/home/Faq";
import BuyCredits from "../Components/dashboard/credit/BuyCredit";
import Forgotpassword from "../Components/home/forgotpassword";
import Resetpassword from "../Components/home/resetpassword";
import Cart from "../Components/dashboard/credit/Cart";
import { Navigate } from "react-router-dom";
import Openinghours from "../Components/home/openinghours";
import Editprofile from "../Components/dashboard/editprofile";
import Original from "../Components/home/Original";
import OriginamTuningFile from "../Components/home/OriginamTuningFile";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import AllNews from "../Components/home/allNews";
import CarTableWithModal from "../Components/dashboard/ChiptuningDatabse";
const RoutesUrl = () => {
  const isTokenValid = () => {
    const token = localStorage.getItem("Access-Token");
    if (!token) return false;

    try {
      const base64Url = token.split(".")[1];
      if (!base64Url) return false;
      const payload = JSON.parse(
        atob(base64Url.replace(/-/g, "+").replace(/_/g, "/"))
      );
      return payload.exp * 1000 > Date.now();
    } catch (e) {
      console.error("Invalid token", e);
      return false;
    }
  };

  const [isAuthenticated, setIsAuthenticated] = useState(isTokenValid());
  const location = useLocation();

  useEffect(() => {
    const checkAuth = () => {
      const isValid = isTokenValid();
      setIsAuthenticated(isValid);
      if (!isValid) {
        localStorage.removeItem("Access-Token");
      }
    };

    checkAuth();
    const interval = setInterval(checkAuth, 60000);
    window.addEventListener("storage", checkAuth);

    return () => {
      clearInterval(interval);
      window.removeEventListener("storage", checkAuth);
    };
  }, []);

  useEffect(() => {
    setIsAuthenticated(isTokenValid());
  }, [location]);

  return (
    <>
      <Routes>
        {/* Modified: Now the Home page is always accessible */}
        <Route path="/" element={<Home />} />
        
        {/* Redirect from login/register to dashboard if already authenticated */}
        <Route
          path="/register"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <Register />
          }
        />
        <Route
          path="/login"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <Login />
          }
        />
        <Route path="/buy-cart" element={<Cart />} />
        <Route
          path="/forgotpassword"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <Forgotpassword />
          }
        />
        <Route
          path="/resetpassword"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <Resetpassword />
          }
        />
        
        {/* Modified: Make home pages accessible for authenticated users too */}
        <Route path="/alientechtools" element={<Alientechtools />} />
        <Route path="/cmdflashtool" element={<Cmdflashtool />} />
        <Route path="/magicmotorsport" element={<Magicmotorsport />} />
        <Route path="/autotuner" element={<Autotuner />} />
        <Route path="/chiptuningfile" element={<Chiptuningfile />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/all-news" element={<AllNews />}/>
        <Route path="/prices" element={<Prices />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/chiptuningbusiness" element={<Chiptuningbusiness />} />
        <Route path="/car-overview/:slug" element={<Caroverveiw />} />
        <Route path="/data" element={<Data />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/openinghours" element={<Openinghours />} />
        <Route path="/tuning-files" element={<Original />} />
        <Route path="/chiptuning-files" element={<CarTableWithModal />} />
        <Route path="/tuning-ecu-files-database/:slug" element={<OriginamTuningFile />} />

        {/* Protected routes - require authentication */}
        <Route
          path="/dashboard"
          element={
            isAuthenticated ? <Dashboard /> : <Navigate to="/login" />
          }
        />
        <Route path="/navbar" element={<Navbar />} />
        <Route
          path="/dashboardhome"
          element={
            isAuthenticated ? <Dashboardhome /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/original-files"
          element={
            isAuthenticated ? <OriginalFiles /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/credit-transactions"
          element={
            isAuthenticated ? <CreditTransaction /> : <Navigate to="/login" />
          }
        />
        <Route 
          path="/subscriptions" 
          element={
            isAuthenticated ? <Subscriptions /> : <Navigate to="/login" />
          } 
        />
        <Route
          path="/demo-files"
          element={
            isAuthenticated ? <DemoFiles /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/support-tickets"
          element={
            isAuthenticated ? <SupportTickets /> : <Navigate to="/login" />
          }
        />
        <Route 
          path="/create-ticket" 
          element={
            isAuthenticated ? <CreateTicket /> : <Navigate to="/login" />
          } 
        />
        <Route 
          path="/add-subscriptions" 
          element={
            isAuthenticated ? <AddSubscription /> : <Navigate to="/login" />
          } 
        />
        <Route
          path="/file-services"
          element={
            isAuthenticated ? <FileServices /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/new-file-services"
          element={
            isAuthenticated ? <NewFileServices /> : <Navigate to="/login" />
          }
        />
          <Route
          path="/edit-file-services/:id"
          element={
            isAuthenticated ? <NewFileServices /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/order-history"
          element={
            isAuthenticated ? <OrderHistory /> : <Navigate to="/login" />
          }
        />
        <Route 
          path="/export-invoice" 
          element={
            isAuthenticated ? <ExportInvoice /> : <Navigate to="/login" />
          } 
        />
        <Route 
          path="/buy-credits" 
          element={
            isAuthenticated ? <BuyCredits /> : <Navigate to="/login" />
          } 
        />
        <Route 
          path="/cart" 
          element={
            isAuthenticated ? <Cart /> : <Navigate to="/login" />
          } 
        />
        <Route
          path="/editprofile"
          element={
            isAuthenticated ? <Editprofile /> : <Navigate to="/login" />
          }
        />
      </Routes>
    </>
  );
};

export default RoutesUrl;