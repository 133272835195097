import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import TopCrumb from "../../header/TopCrumb";
import { Badge, Card, Spinner } from "react-bootstrap";
import Datatable from "../datatable";
import { getProfile, listOrders } from "../../../api";

const CreditTransaction = () => {
  const [creditValue, setCreditValue] = useState(0);
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true); // ⬅️ new state for loading

  useEffect(() => {
    const fetchCreditsAndOrders = async () => {
      try {
        const token = localStorage.getItem("Access-Token");

        const profileResponse = await getProfile(token);
        if (profileResponse?.detail?.credit?.length) {
          const masterCredit = profileResponse.detail.credit.find(
            (item) => item.type === "master-credits"
          );
          if (masterCredit) {
            setCreditValue(masterCredit.value);
          }
        }

        const ordersResponse = await listOrders(token);
        if (ordersResponse?.data?.length) {
          const masterCreditsOnly = ordersResponse.data
            .filter((order) =>
              order.credit?.some((c) => c.type === "master-credits")
            )
            .map((order) => {
              const masterCredit = order.credit.find(
                (c) => c.type === "master-credits"
              );

              return {
                date: new Date(order.updated_at * 1000).toLocaleDateString("en-GB"),
                description: `Order ID: ${order.order_id}`,
                type: "Master Credits",
                credits: masterCredit?.value ?? 0,
                status: order.status,
              };
            });

          setOrderData(masterCreditsOnly);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // ⬅️ hide loader after fetch completes
      }
    };

    fetchCreditsAndOrders();
  }, []);

  const columns = [
    { name: "Date", selector: (row) => row.date, sortable: true },
    { name: "Order Id", selector: (row) => row.description },
    { name: "Type", selector: (row) => row.type },
    { name: "Credits", selector: (row) => `➜ ${row.credits}` },
    {
      name: "Status",
      cell: (row) => (
        <Badge
          className="badge_design"
          bg={row.status === "Pending" ? "warning" : "success"}
          text={row.status === "Pending" ? "dark" : "light"}
        >
          {row.status}
        </Badge>
      ),
    },
  ];

  return (
    <Wrapper>
      <TopCrumb route={"Credit transactions"} />
      <div className="row my-1 p-3">
        <div className="col-md-12">
          <div className="row">
            <div className="col-lg-6 col-md-5 col-12" style={{ height: "auto" }}>
              <Card className="credit_cards">
                <div className="Dashboard_heading_2">
                  <h4 className="Dashboard_heading_1">
                    <i className="bi bi-credit-card"></i> Master credits
                  </h4>
                  <svg
                    className="icon_arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                  </svg>
                </div>
                <h3 className="mb-3">{creditValue.toFixed(2)}</h3>
              </Card>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <Card className="mt-3 p-3">
            {loading ? (
              <div className="text-center my-5">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <Datatable title="Transactions" columns={columns} data={orderData} />
            )}
          </Card>
        </div>
      </div>
    </Wrapper>
  );
};

export default CreditTransaction;
